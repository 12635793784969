import {React, useState, useEffect} from 'react';
import './TopNav.css';
import leafletImg from '../assets/images/views/leaflet.png';
import Dropdown from './Dropdown';
import '../styles/material-symbols-outlined.css';
import {API_BASE_URL} from '../constants/constants'

function TopNav( {map}) {
  return (
    <div className="top-nav">
      <MapView map={map}/>
    </div>
  );
}

const getTiles = (lng, lat, zooom) =>{
  // calculate the tile coordinates for the given longitude, latitude and zoom level
  let x = parseInt(Math.floor((lng+180)/360*(1<<zooom)));
  let y = parseInt(Math.floor((1-Math.log(Math.tan(Math.PI*lat/180) + 1/Math.cos(Math.PI*lat/180))/Math.PI)/2*(1<<zooom)));
  return `x=${x}, y=${y}, z=${zooom}`;
}

function MapView({map}) {
  // get the list of basemaps and labels
  const [basemaps, setBasemaps] = useState(null);
  const [labels, setLabels] = useState(null);
  const [lat, setLat] = useState(0.0);
  const [lng, setLng] = useState(0.0);
  const [timeLabel, setTimeLabel] = useState('x:0, y:0, z:0');
  const [latLng, setLatLng] = useState(false);

  const [loading, setLoading] = useState(true); // state for indicating if it is still loading data in

  // updates the lat/lng or the xyz values
  const updateCoordInfo = () => {
    if (map){
      if(latLng){
        // display in lat and lng
        // convert +/- to N/S
        let latDirection = lat >= 0 ? 'N' : 'S';
        let latStr = Math.abs(lat).toFixed(2) + '°' + latDirection;

        let lngDirection = lng >= 0? 'E' : 'W';
        let lngStr = Math.abs(lng).toFixed(2) + '°'+ lngDirection;

        setTimeLabel(latStr + ' ' + lngStr);
      } else {
        // display as x y coordinates
        let zoom = map.getZoom();
        let tiles = getTiles(lng, lat, zoom);

        setTimeLabel(tiles);
      }
    }
  }

  // setting on click listener for the map
  if(map){
    map.on('click', function (e){
      setLat(e.latlng.lat)
      setLng(e.latlng.lng)
    })
  }

  useEffect(() =>{
    updateCoordInfo();
  }, [lat, lng, latLng])
  

  // once the element is clicked, the latLng is updated
  const handleCoordClick = () => {
    setLatLng(!latLng);  
  };

  // function fetches for the basemap/labels
  useEffect(()=>{
    const fetchAncillary = async() => {
      try{
        // fetch basemaps/labels
        const response = await fetch(`${API_BASE_URL}/ancillary`);
        const ancillary = await response.json();
        
        setBasemaps(ancillary.basemap);
        setLabels(ancillary.labels);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching basemaps/labels', error);
        setLoading(false);
      }
    };

    fetchAncillary();
  }, []);

  return (
    <div className='top-nav-container'>
      <div className='top-left'>
        <button className="map-view">
          <span className='view-icon'>
            <img src={leafletImg} alt="Leaflet logo" />
            <div className='down-arrow'></div>
          </span>
        </button>

        <div className='dropdown-container'>
          <Dropdown map={map} type="Basemap" data={basemaps}/>
          <Dropdown map={map} type="Label" data={labels}/>
        </div>
        <div className='info-container'>
          <label className='curr-time'>2024-08-19 1620UTC</label>
          <label className='curr-tile' onClick={handleCoordClick}>{timeLabel} </label>
        </div>

      </div>
      <div className='top-right'>
        <span className="material-symbols-outlined nav-icons">
          search
        </span>
      </div>
    </div>
  )
}

export default TopNav;