
import './styles/App.css';
import 'leaflet/dist/leaflet.css';
import { useState } from'react';
import TopNav from './components/TopNav';
import BottomNav from './components/BottomNav';
import MapComponent from './components/MapComponent';


function App() {
  // this stores map so it can be passed down to other components
  const [mapInstance, setMap] = useState(null);

  const mapLoad = (map) => {
    setMap(map);
  }

  return (
    <div className="App">
      <header className="App-header">
        <TopNav map={mapInstance}/>
        <MapComponent onMapLoad={mapLoad}/>
        <BottomNav />
      </header>
    </div>
  );
}
export default App;
