import React from 'react';
import './NavButton.css'
import '../styles/material-symbols-outlined.css'

function NavButton({ label, icon}) {
    return <button className="nav-button">
      <span className="material-symbols-outlined nav-icons">
        {icon}
      </span>
      <span className='nav-label'>{label}</span>
    </button>;
}

export default NavButton;