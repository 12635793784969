import {React, useEffect} from 'react';
import { MapContainer, useMap, ScaleControl, FeatureGroup} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css';
import '../leaflet';
import './MapComponent.css'
import '../styles/material-symbols-outlined.css';
import 'leaflet-draw/dist/leaflet.draw.css';

function AttControl() {
  const map = useMap();

  useEffect(() => {
    // Create a new attribution control
    const attributionControl = L.control.attribution({ position: 'topright' });
    
    // Add it to the map
    attributionControl.addTo(map);

    // remove the control when unmounts
    return () => {
      map.removeControl(attributionControl);
    };
  }, [map]);

  return null;
}

function BaseMap() {
  const map = useMap();

  useEffect(() =>{
    const baseMap = L.tileLayer("https://realearth.ssec.wisc.edu/api/image?background=satellite&x={x}&y={y}&z={z}", {attribution: "RealEarth Basemaps, Labels"});
    baseMap.isBaseMap = true;

    baseMap.addTo(map)
  }, [map]);
}

function LocateButton() {
  const map = useMap();

  /** 
    * change the location to the user's current location
  */
  const flyToCurrLoc = () => {
    if (navigator.geolocation){
      // checks if the browser supports geolocation
      navigator.geolocation.getCurrentPosition(function(position) {
        // if successfully geolocated, then fly to the location
        map.flyTo([position.coords.latitude, position.coords.longitude], map.getZoom());
      }, function(error) {
        console.error("Geolocation error:", error);
      });
    } else {
      alert("Geolocation is not supported by the browser");
    }
  }

  return(
    <button className="locate-button" onClick={flyToCurrLoc}>
      <span className="material-symbols-outlined locate-icon">
          my_location
      </span>
    </button>
  );
}

function MapComponent({onMapLoad}) {
  const position = [40.07, -89.4];

  function MapWrapper() {
    const map = useMap();

    useEffect(() => {
      if(onMapLoad) {
        onMapLoad(map); // this passes the current map instance upwards
      }
    }, [map, onMapLoad])

    return null;
  }

  return (
    <MapContainer 
      center={position} 
      zoom={3} 
      scrollWheelZoom={true} 
      style={{ height: "100vh", width: "100%"}}
      attributionControl={false} // disable because want to manually add it
    >
      <MapWrapper />
      <BaseMap/>
      <AttControl/>
      <ScaleControl position="bottomright" />

      {/* Drawing toolbar */}
      <FeatureGroup>
        <EditControl
          position='bottomright'
          onCreated={(e)=>{/*do something*/}}
          draw={{
            polyline: true,
            polygon: true,
            circle: true,
            marker: true,
            rectangle:false,
            circlemarker: false
          }}
          edit={{
            remove: true,
            edit: true,
          }}
        />
      </FeatureGroup>

      <LocateButton />

    </MapContainer>
  );
}

export default MapComponent;