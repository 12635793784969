import React from 'react';
import NavButton from './NavButton';
import InsertButton from './InsertButton';
import './BottomNav.css'

function BottomNav() {
  return (
    <div className="bottom-nav">
      <NavButton label="user" icon="account_circle" />
      <NavButton label="animation" icon="animation" />
      <InsertButton />
      <NavButton label="settings" icon="settings"/>
      <NavButton label="share" icon="share"/>
    </div>
  );
}

export default BottomNav;