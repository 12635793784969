import {React, useState, useEffect, useRef} from 'react';
import L from 'leaflet';
import './Dropdown.css';

function Dropdown({map, type, data}) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(type); 

  const dropdownRef=useRef(null);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  // handles the changing of basemap/label
  const handleClick = (layer) => {
    setOpen(false);
    setTitle(layer['name']);

    // going through each layer and remove the basemap layer
    if(type === 'Basemap'){
      // changing the basemap

      map.eachLayer(function(layer) {
        if (layer.isBaseMap) {
          map.removeLayer(layer);
        }
      });

      // creating new layer to add to the map
      const baseMap = L.tileLayer(layer.templates['api'], {attribution: "RealEarth Basemaps, Labels"})
      baseMap.isBaseMap = true;

      baseMap.addTo(map);
      // ensure that it is the bottom most layer
      baseMap.bringToBack();
    } else{
      // changing the label
      map.eachLayer(function(layer) {
        if (layer.isLabel) {
          map.removeLayer(layer);
        }
      });
      
      const labelLayer = L.tileLayer(layer.templates['api'], {attribution: "RealEarth Basemaps, Labels"})
      labelLayer.isLabel = true;
      
      labelLayer.addTo(map);
    }
  }

  // add event listener to handle click outside dropdown to close it
  useEffect(() => {
    function clickedOutside(event) {
      if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // event listener listening to outside event
    document.addEventListener('mousedown', clickedOutside);
    // cleanup on unmount
    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    };

  }, [dropdownRef]);

  
  return (
    <div className='dropdown' ref={dropdownRef}>
      <button className="dropdown-button" onClick={toggleDropdown}>
        <span className="title-container">
          <span className='title'>{title}</span>
          <span className='title-divider'>|</span>
          <div className='down-arrow'></div>
        </span>
        
        </button>

      {open && (
        <div className='dropdown-content'>
          <ul className='provider-list'>
            {data && Object.keys(data).map((provider)=>(
              <li className='provider-item'>
                <strong>{provider}</strong>
                  <ul className='layer-list'>
                    {Object.keys(data[provider]).map((layer) => (
                      <li className = 'layer-item' onClick={()=>handleClick(data[provider][layer])}>
                       {data[provider][layer]['name']}
                      </li>
                    ))}
                  </ul>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Dropdown;